.main-header {
  
    width: 100%;
    background-color:rgba(254, 255, 255, 0.993);
    height:40px;

  display: inline-flex;
    /* box-shadow: 10px 13px 15px rgba(0, 0, 0, 0.308); */
    opacity: .97;
  }
  
div .nav-links *{
  margin-right:30px;
}
  
  .main-header h1 {
    text-align: center;
    margin:auto;

    width: fit-content;
    font-size: 16px;
  }
  
  .nav-links {
    display: inline-flex;
    float: right;
    font-size: 14px;
    padding:10px;
  
  }
  
  .nav-links a {
    margin-left: 1em;
    text-decoration: none;
    font-size:1.2em;
    color: #39134c;
  }
  
  .nav-links p {
    display: inline;
    cursor: default;
  }
  
  .nav-links a:hover {
    opacity: 0.7;
  }
  
  .deal {
    font-size: 1.3em;
    color: #39134c;
  }
  .meal{
    font-size: 1.3em;
    color: #39134c;
  }
  p#user-name{
    position: absolute;
    left: 50px;
    top: 10px;
    color: #39134c;
  
  }
  @media only screen and (max-width: 760px) {
   
    .nav-links a, .nav-links p {
      font-size: .85em;
    }
  
    .main-header h1 {
    text-align: left;
    }
  
    div .nav-links *{
      margin-right:10px;
    }
  
   
  }
  