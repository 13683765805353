
.meal-item {
    height: auto;
    min-height: 80px;
    max-height: 95px;
    /* width:325px; */
    width: 100%;
    display: inline-flex;
    padding: .5em;
    box-shadow:
    0 0.8px 0.8px -20px rgba(0, 0, 0, 0.039),
    0 1.9px 2px -20px rgba(0, 0, 0, 0.057),
    0 3.5px 3.8px -20px rgba(0, 0, 0, 0.07),
    0 6.3px 6.7px -20px rgba(0, 0, 0, 0.083),
    0 11.7px 12.5px -20px rgba(0, 0, 0, 0.101),
    0 28px 30px -20px rgba(0, 0, 0, 0.14)
  ;
  margin: 0px auto 10px;
    background-color: rgb(248, 248, 248);
    position: relative;
}
.meal-item img{
    height:80px;
  float: right;
}
.mod-item img{
    margin: 1px;
    height:75px;
  float: right;
}
.mod-item{
    background-color:  #006edce8;
    color:white;
   position: relative;
    width:95vw;
padding:1px;
margin:15px 0px;
border-radius: 5px;
    height:80px;
 
}
.item-buttons button{
    padding: 0px;
    margin:0px 7px;
    height: 24px;
    border: none;
    width: 24px;
    border-radius: 12px;
    color:black;
    background-color: transparent;
}
.item-buttons button:hover{
    background-color: black;
    color: white;
}
.mod-item .item-buttons button{
    border: 1px solid white;
  margin:0px 7px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    color:white;
    background-color: transparent;

}
.mod-item .item-buttons {
    display:inline-flex;
    flex-direction: row;
width: fit-content;
position: absolute;
bottom:2px;
left:0px;
}
.mod-item .item-buttons button:hover{
    background-color: white;
    color: black;
}
.meal-item a{
    text-decoration: none;

}

.item-buttons{
    height:auto;
 display: flex;
 flex-direction: column;
}
.div.item-buttons button{

    /* padding:5px 5px; */
}

p.meal-name{

    font-size:.9em;
    padding:5px;
    text-align: center;
    margin:auto
}
.edit-meal-page{
    background-color: pink;
    height:100vh;
    z-index:99;
}
#item-selected{
  
 height:auto;
 min-height:70vh;
 width:90vw;
 min-width:70vw;
 
 max-height:fit-content;
  list-style: none;
border:1px solid black;
border-radius:3px;
text-align: center;

box-shadow: 2px 2px 1px grey;

padding:1.5em;
margin-left:auto;
margin-right:auto;
margin-top:50px;
}

.meal-item-header{
    position:sticky;
    top: 0;
    height:100px;
    z-index: 9;
}





button.see-more-btn{
   
}
/* .place-holder{
    list-style: none;
    text-align: center;
    margin: 30px;
} */
.lists{
    width:100%;
    text-align: left;
    margin:auto;
   display: flex;
    flex-direction: row;
   flex-wrap: wrap;
}
ul.ing-list{
    /* display: block; */
    margin:auto;
    margin-bottom:10px;
    /* min-height:200px; */
    border-radius:3px;
    box-shadow: 2px 2px 1px grey;
    text-align: left;
  
    background-color: rgb(236, 236, 236);
  
  min-width:35vw;
  max-width:80vw;
    margin-left:30px;
    height:fit-content;
    min-height:20vh;
    padding:1em;
    padding-left:20px;
    opacity:100%;
}
ul.inst-list{
  
    margin:auto;
    /* min-height:200px; */
    
    background-color: rgb(236, 236, 236);
    border-radius:3px;
    box-shadow: 2px 2px 1px grey;
    min-width:35vw;
    max-width:80vw;
    text-align: left;
    max-width:80vw;
    margin-left:30px;
    height:fit-content;
    min-height:20vh;
    padding:1em;
    padding-left:20px;
    opacity:100%;
}


/* //// DESKTOP VIEW ////// */
@media only screen and (min-width: 760px) {

      #item-selected {
        position: fixed;
        right:0;
        left:0;
        top:60px;
        height:auto;
        max-height:fit-content;
        padding:3em;
        margin:auto;
        
    }
  ul.ing-list{
    margin-bottom: 15px;
      margin-left:auto;
      margin-right: auto;
      margin-bottom:15px;
      width:100%;
      max-width:40vw;
      opacity: 90;
      padding-left:40px;
      height:auto;
  }
  ul.inst-list{
    margin-bottom:15px;
    max-width:50vw;
    margin-left:auto;
    margin-right: auto;
      width:100%;
      max-width:40vw;
      opacity: 90;
      padding-left:40px;
      height:auto;
}
     
.mod-item{
    background-color:  #006edce8;
    color:white;
    width:215px;
padding:1px;
margin:0 5px;
border-radius: 5px;
    height:80px;
    border-bottom: .5px solid rgb(255, 255, 255);
    border-right: .5px solid rgb(255, 255, 255);
}
}