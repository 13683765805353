ul{
    padding:0;
}
ul .bm-list{
 
    min-height: 90vh;
    height:auto;    
    width:100%;
   
}
.bm-list li{
    list-style: none;
}

@media only screen and (min-width: 760px) {
  
}