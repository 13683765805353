.shopping-list{
    list-style: none;
    width:100%;
    height:auto;
}
.shopping-item{
  padding:10px;
    max-width:95vw;
    margin: auto;
    margin-bottom: 10px;
    border:.5px solid black;
    box-shadow: 5px 5px 3px grey;
}
@media only screen and (min-width: 760px) {
    .shopping-item{
        margin-bottom: 20px;
        max-width:35vw;
        
    }

   
}