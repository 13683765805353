
ul{
    padding:0;
}
ul .result-list{
 
    /* min-height: 700px; */
    height:auto;    
    width:100%;
   
}
.result-list li{
    list-style: none;
}

@media only screen and (min-width: 760px) {
  
}


.search-results{
    list-style: none;
    display:contents;
     margin:auto;
    height:fit-content;
}

input#explorer_search_term.Input{
    background-color: white;
}
.result-item{
    margin-top:25px;
    box-shadow: 1px -1px 10px 6px rgb(228, 158, 180);
   
    padding:20px;
    width:95vw;
    min-height:fit-content;
    overflow: auto;
    border:2px solid rgb(228, 158, 180);
    margin-left:auto;
    margin-right:auto;

}

.result-item-img{
    max-width: 150px;
  
}
div.results-container{
    margin:auto;
    min-height:fit-content;
width:100%;
    height:auto;
    
  
}
.meal-options{
    margin-left:20px;
}
ul.ingredient-list{
 list-style: circle;
 text-align: left;
 font-size: .75em;
 margin:auto;

 height:auto;
}


@media only screen and (min-width: 760px) {

    .search-results .exp-page{
        /* margin: auto; */
        background-color: rgb(231, 231, 231);
    height: auto;
    width: 30vw;
    float: left;
    margin-left:30px;
    }
    .result-item .exp-page{
        background-color: pink;
        max-width: 500px;
        border-radius: 8px;
    }


    .result-item{
        min-height:200px;
        margin:auto;
        margin-top:25px;
        margin-bottom:25px;
        height:fit-content;
        width:45vw;
        border-radius: 8px;
        display:block;
        box-shadow:    6px 10px 3px 6px rgb(228, 158, 180);
    }
    .result-item-img{
        max-width:220px;
        margin-top:20px;
        float:left;
        margin-right:30px;
    }
    ul.ingredient-list{
        float: right;
        text-align:left;
        margin-top: 10px;
        font-size: .95em;
        
    }
   
   .search-results{
       height:auto;
       width:49vw;
       padding:10px;
        margin:auto;
      
        text-align:none;
    }
  
}