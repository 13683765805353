div.react-calendar{       
    width:100%;
    height:auto;
    max-width: calc(100vw - 490px);
    margin: 0px auto;

  }


 .react-calendar__tile{
   /* max-height:110px; */
   height: 13vh;
   text-align:right;
   padding: 0px 3px;
   font-size: .7em;

 }
 .react-calendar__tile p{
 
  text-align:center;
  margin:2px 1px 5px;
 }
 .tile-text{
   color:blue;
   
 }
 .react-calendar__navigation button[disabled] {
  background-color: white;
}

.react-calendar__navigation__next2-button[disabled]{
  display: none;
}
.react-calendar__navigation__prev2-button[disabled]{
  display: none;
}
 .react-calendar__navigation__label{
   font-size: 2.2em;
   color:black;
 }

 .react-calendar button{
  border-right: .5px solid black;
  border-bottom: .5px solid black;
 }

 abbr[title] {
  text-decoration: underline ;
}


 /* WEEKEND */
 .react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border:none;
}

/* MOBILE VIEW */
@media only screen and (max-width: 760px) {
  .react-calendar__tile{
    max-height:100px;

    text-align:left;
    font-size: .7em;
 
  }
  div.react-calendar{       
    max-width:100vw;
 

  }
}