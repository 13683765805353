
.planner-page{
min-height: calc(100vh - 80px);
  width:100%;


}
button.search-form-btn{
  height:40px;
  width: 40px;
  margin: 5px;
  border-radius: 20px;
  background-color: transparent;
}
button.search-form-btn.disabled{
  background-color: yellow;
  pointer-events: none;

}
.mod-container{
  background-color: rgba(214, 214, 214, 0.705);
  text-align: center;
}
.bm-header{
  text-align: center;
  margin:10px;
}
/* DESKTOP VIEW */
@media only screen and (min-width: 760px) {
 

      .planner-page {
        height:calc(100vh - 80px);
        width:100%;
        display: grid;
        grid-template-rows: 90px 1fr;
        grid-template-columns: 350px 1fr;
   
        grid-row-gap: 0px;
        overflow-y: scroll;
        grid-template-areas:
        "search-form mod"
        "results calendar"
     
        }
        
       
        .res-container { 
          display: flex;
          height:calc(100vh - 180px);
          flex-direction: column;
          background-color:rgba(254, 255, 255, 0.993);

          overflow-y: auto;
         
          grid-area: results;
        border-right: 1px solid rgba(128, 128, 128, 0.795);
        }
        .search-form-container { 

          grid-area: search-form; 
          border-bottom: 1px solid black;
        }
     
     .mod-container{
    
       display: flex;
       flex-direction: row;
       grid-area: mod;
       height:90px;
   /* width:100%; */
   width: calc(100vw - 415px);
   margin: auto;
   border-left:.5px solid rgba(128, 128, 128, 0.363);
   border-bottom:.5px solid rgba(128, 128, 128, 0.363);
   box-shadow:         inset 0 0 5px #959595;
     }
     button:hover{
       cursor: pointer;
     }


 .calendar-container{
  grid-area: calendar; 
   height:auto;
   max-height:calc(100vh - 180px);
   width:100%;
   max-width: calc(100vw - 415px);
  padding:5px 5px;
 }





}

  
  
 