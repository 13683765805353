

.mod-list{
  height:auto;
  width:fit-content;
margin: auto;
  list-style: none;
  
}
.mod-page{
    height:auto;
    min-height:200px;
    width:100%;
    /* overflow-y:auto; */
   
}
.mod-day{
   padding:3px;
    color:  #006edc;
    margin:auto;

    font-weight: bolder;
}

@media only screen and (min-width: 760px) {
    .mod-day{
   
         color:  #006edc;
    

     }

   
    .mod-list{
        overflow-x: auto;
       
        margin:auto;
       display: flex;
       flex-direction: row;
    }
   


}
