.calendar-page{
    height:100vh;
    width:100%;
    /* background-color: indianred; */
}

.cal-page-btns{
margin:10px;    display: flex;
    flex-direction: row;
}
  .cal-page-btn{

       margin:auto;
       max-width: 500px;
       max-height: 500px;
       height:15vh;
       width:30vw;
      
       cursor: pointer;
       color: rgb(244, 248, 16);
       font-size: 3rem;
       text-shadow:7px 10px 10px rgb(29, 29, 29);
       text-align: center;
       border-radius:5px;
       box-shadow: 8px 10px 17px rgba(29, 29, 29, 0.815);
    }

    .cal-page-btn:hover{
        color: white;
    }