div.sidebar{
    min-height:100vh;
   
    display: flex;
    flex-direction: column;
    width:40px;
    background-color: rgb(255, 255, 255);
   
}
div.sidebar-icons{
    width:100%;
    height: auto;
   text-align: center;
  margin: auto;
}



