.SideBarNav{
    display:fixed;
    /* flex-direction: column; */
    height:100vh;
    width:100px;
    background-color:floralwhite;
    border-right:1px solid grey;
    visibility: visible;
}

.nav-icon{
    display:block;
    height:25vh;
    border-bottom:1px solid grey;
    width:150px;
    margin:auto;
}
.nav-icon:hover{
    cursor: pointer;
    background-color: lightpink;
}


.landing-btn{
    display:block;
    width:75vw;
    height:20vh;
    margin:auto;
    margin-top:75px;
    cursor: pointer;
    color: rgb(244, 248, 16);
    font-size: 3rem;
    text-shadow:7px 10px 10px rgb(29, 29, 29);
    text-align: center;
    border-radius:5px;
    box-shadow: 8px 10px 17px rgba(29, 29, 29, 0.815);
}

/* a.landing-page-link{
    width: 100%;
    height:auto;
} */
.explore-btn{
    background: url('../../images/explorer-pic.jpg')no-repeat center;
   
}

.shoplist-btn{  
    background:url('../../images/list-pic.jpg')no-repeat center;
}
.planner-btn{
    background: url('../../images/plan-pic.jpg')no-repeat center;
}


.landing-page{
    height:auto;
    width:100%;
}
@media only screen and (min-width: 760px) {
   .landing-btn{
    max-width: 500px;
    max-height: 500px;

    width:45vw;
    height:23vh;
    /* cursor: pointer; */
   }    
   .landing-btn:hover{
       transform: scale(1.1);
       transition-duration: 950ms;
   }
}
