.searchForm{
    width:fit-content;
    float: right;
    display: inline-flex;
}
.search-form-btn{
    display: inline-block;
}




.submit-search-btn{
    width:75px;
    border-radius: 10px ;
    border:2px solid rgb(38, 207, 89);
    height:fit-content;
    background-color: rgb(233, 233, 233);
    cursor: pointer;
    text-align: center;
}
.submit-search-btn:hover{
    background-color: rgb(47, 255, 109);
}

/* 
DESKTOP STYLES */
@media only screen and (min-width: 760px) {
   
    .submit-search-btn{
        width: 75px;
        height: 25px;
        border-radius: 10px ;
     
    
    }
    .searchTerm{
        padding:1px;
       
    }
    
    input#search_search_term{
       background-color:rgb(248, 246, 246);
    height:25px;
    width:160px;
    margin:0px 5px;
    text-align: center;
   }
}