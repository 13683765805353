.reg-header label {
    margin-right: 4px;
  }
  
  .registration-form{
      opacity:.95;
      border:1px solid black;
      padding:40px;
      max-width: 400px;
      margin:auto;
      border-radius:10px;
      text-align: end;
      background-color: white;
      box-shadow:
      0 1.7px 2.2px rgba(0, 0, 0, 0.104),
      0 4.2px 5.3px rgba(0, 0, 0, 0.149),
      0 7.9px 10px rgba(0, 0, 0, 0.185),
      0 14.1px 17.9px rgba(0, 0, 0, 0.221),
      0 26.3px 33.4px rgba(0, 0, 0, 0.266),
      0 63px 80px rgba(0, 0, 0, 0.37)
    ;
      position: relative;
  }
  .registration-form *{
      margin:2px;
  }

  .registration-button{
      border:.5px solid black;
      background-color: white;
      border-radius: 10px;
      margin-bottom:8px;
      padding-top:2px;
      padding-bottom: 2px;
  }
  
  .registration-button:hover{
      background-color: rgb(4, 168, 4);
      color:white;
      cursor: pointer;
  }
  
  .login-redirect:hover{
      border-bottom: 1px solid blue;
      color:blue;
      cursor: pointer;
  } 
  
  .auth-error {
    color: red;
    display: inline-block;
    font-weight: bolder;
    width: 90%;
  }
  
  .auth-error-container {
  
    text-align: center;
    margin: auto;
    color: red;
  }
  

  @media only screen and (max-width: 760px) {
  
      .registration-form{
          padding:20px;
      }
      .registration-button{
          background-color: rgb(4, 168, 4);
          color:white;
     
      }
      /* .auth-error-container {
        bottom: -100px;
      } */
    
  }