.log-reg-header {
  margin: 0px 0px 10px 0px;
    text-align: left;
  }
  
  .login-input label {
    margin-right: 4px;
  }
  
  .log-in-form{

    padding: 30px 30px 10px;
      max-width: fit-content;
      margin:auto;
      border-radius:10px;
      text-align: end;
      background-color: white;
      box-shadow:
      0 1.7px 2.2px rgba(0, 0, 0, 0.104),
      0 4.2px 5.3px rgba(0, 0, 0, 0.149),
      0 7.9px 10px rgba(0, 0, 0, 0.185),
      0 14.1px 17.9px rgba(0, 0, 0, 0.221),
      0 26.3px 33.4px rgba(0, 0, 0, 0.266),
      0 63px 80px rgba(0, 0, 0, 0.37)
    ;
    
      /* margin-top:20vh; */
      position: relative;
  }
  .log-in-button{
      margin:5px 0px;
      border:.5px solid black;
      border-radius: 10px;
      padding-top:2px;
      padding-bottom: 2px;
      background-color: white;
  }
  
  .log-in-button:hover{
      background-color: rgb(4, 168, 4);
      color:white;
      cursor: pointer;
  }
  
  .login-redirect :hover{
      border-bottom: 1px solid blue;
      color:blue;
      cursor: pointer;
  }
  
  a.login-redirect {
    margin: 15px 0px;
   font-size: .9em;
}
  
  .login-error {
    color: rgb(253, 71, 71);
    font-weight: bolder;
   
  }
  
  .log-error-container {
  
    text-align: center;
    margin: auto;
    color: red;
 
  }  
  .login-input{
    margin: 5px 0px;
  }