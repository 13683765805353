/* .add-meal-form{
    text-align: left;
    margin:auto;
    padding:30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(107, 107, 107, 0.904);
    background-color: rgb(97, 230, 212);
    width:90%;

   
} */


/* 



.add-meal-page{
  height:auto;
  width:100%;
}

.form-add-btn{
  background-color: rgba(209, 212, 212, 0.932);
  border:1px solid black;
  float:right;
}

.form-add-btn:hover{
background-color: rgb(0, 255, 255);
}

.add-meal-inst-list{
  margin-top:2px;
  margin: auto;
  text-align: left;
  min-height:100px;
  display: block;
  height:auto;
  width:80%;
}
.add-meal-ing-list{
  margin: auto;
  text-align: left;
  min-height:100px;
  display: block;
  height:auto;
  width:80%;
  
}
.instructions-input{
  border:1px solid black;
  margin-top:30px;
  padding:10px;
  box-shadow: 0px 0px 5px rgba(107, 107, 107, 0.616);
  border-radius: 5px;
}
.ingredients-input{
  margin-top:30px;
  border:1px solid black;
  padding:10px;
  box-shadow: 0px 0px 5px rgba(107, 107, 107, 0.616);
  border-radius: 5px;
} */
/* @media only screen and (min-width: 760px) { */
  .add-meal-form{
    display: inline-block;
    background-color:  #006edce8;
    border-radius: 5px;
    color:white;
    width:210px;
  padding:1px;
  margin:0 5px;
    height:80px;
    border-bottom: .5px solid rgb(255, 255, 255);
    border-right: .5px solid rgb(255, 255, 255);
  }
  .add_meal_form{
text-align: center;
  }
  .form-add-btn{
    margin: 5px;
    text-align: center;
    float: right;
    height:30px;
    width:30px;
    border-radius: 15px;
  }

  .form-add-btn:hover{
    color:white;
    background-color: #006edce8;
  }
/* } */