*{
    box-sizing: border-box;
}
.App {
  display: grid;
  grid-template-columns:1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-template-areas:
    "header"
    "main"
    "footer";
  min-height: 100vh;
  width:100vw;
  max-width: 100vw;
}
header {
  grid-area: header;
  background-color:rgba(254, 255, 255, 0.993);
}

main {
  grid-area: main;
  background-color:rgba(254, 255, 255, 0.993);
}

footer {
  grid-area: footer;
  background-color:rgba(254, 255, 255, 0.993);
}
header footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  /* background-color: #648ca6; */
}
aside {
  display:none;
}
/* .App__main{
    height:auto;
    width:100%;
    overflow-y: scroll;
} */
@media only screen and (min-width: 760px) {
    .App {
        display: grid;
        grid-template-columns:40px 1fr;
        grid-template-rows: 40px 1fr 40px;
  

        grid-template-areas:
          "aside header"
          "aside main"
          "aside footer";
       
        max-height: 100vh;
      }
      aside {
        /* display:none; */
        grid-area: aside;
        background-color:rgba(254, 255, 255, 0.993);
     
      }
      header {
        grid-area: header;
        background-color:rgba(254, 255, 255, 0.993);
      }
      
      main {
        grid-area: main;
        /* background-color:rgb(248, 248, 248); */
      }
      
      footer {
        grid-area: footer;
        background-color:rgba(254, 255, 255, 0.993);
      }
      header footer {
      padding:0px;
      }
}